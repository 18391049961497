<template>
  <div :class="[
    'sidebar-area position-fixed start-0 top-0 bg-gray h-100vh transition',
    { active: stateStoreInstance.open },
    
  ]" id="sidebar-area">
    <div class="logo position-absolute start-0 end-0 top-0 bg-gray duration-500 ">
      <router-link to="/" class="d-flex align-items-center text-white text-decoration-none">
        <!--  login proproxy  -->
        <!-- <img src="../../assets/logo1.png" alt="logo-icon" class="w-12 proimg aspect-ratio m-0" />
        <span class="fw-bold ms-10 flex text-black"><p class="text-blue-400">Pro</p>Proxies</span> -->
        <!--  Global Proproxy   -->
        <img v-if="isDarkMode" src="../../assets/logo2.png" alt="logo-icon" class="globalimg w-[150px] aspect-ratio mx-auto " />
        <img v-else src="../../assets/logo.png" alt="logo-icon" class=" globalimg aspect-ratio mx-auto w-[150px]" />
        <!--   Proxy Heros   -->
        <!-- <img v-if="isDarkMode" src="../../assets/logo4.png" alt="logo-icon" class="globalimg w-[150px] aspect-ratio mx-auto " />
        <img v-else src="../../assets/logo3.png" alt="logo-icon" class=" globalimg aspect-ratio mx-auto w-[150px]" /> -->
      </router-link>
      <div class="border-bottom" style="padding-top: 15px;"></div>
      <button class="sidebar-burger-menu position-absolute lh-1 bg-transparent p-0 border-0"
        @click="stateStoreInstance.onChange">
        <i class="ph-duotone ph-caret-double-right"></i>

      </button>
    </div>
    <div class="sidebar-menu" v-if="user.role==='admin'">
      <ul class="sidebar-navbar-nav ps-0 mb-0 list-unstyled accordion" id="sidebarNavAccordion">
        <li class="sidebar-nav-item">
          <router-link to="/admin/server" class="sidebar-nav-link  d-block">
            <i class="flaticon-list"></i>
            <span class="title">Server</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/admin/proxy" class="sidebar-nav-link d-block">
            <i class="flaticon-cubes"></i>
            <span class="title">Proxy</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/admin/license" class="sidebar-nav-link  d-block">
            <v-icon icon="mdi-license"></v-icon>
            <span class="title">License</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/admin/package" class="sidebar-nav-link  d-block">
            <i class="flaticon-document"></i>
            <span class="title">Package</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/admin/coupon" class="sidebar-nav-link  d-block">
            <i class="fa fa-money"></i>
            <span class="title">Coupon</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/admin/customer" class="sidebar-nav-link  d-block">
            <v-icon icon="mdi-account-multiple"></v-icon>
            <span class="title">Customer</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/admin/transaction" class="sidebar-nav-link  d-block">
            <v-icon icon="mdi-swap-horizontal"></v-icon>
            <span class="title">Transaction</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/admin/reseller" class="sidebar-nav-link  d-block">
            <v-icon icon="mdi-account-key"></v-icon>
            <span class="title">Reseller</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/admin/referrer" class="sidebar-nav-link  d-block">
            <v-icon icon="mdi-account-tie"></v-icon>
            <span class="title">Affiliate program</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/account" class="sidebar-nav-link  d-block">
            <v-icon icon="mdi-account"></v-icon>
            <span class="title">Account</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/admin/configuration" class="sidebar-nav-link  d-block">
            <v-icon icon="mdi-cog-outline"></v-icon>
            <span class="title">Configuration</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/admin/chat" class="sidebar-nav-link  d-block ">
            <v-icon icon="mdi-message-text"></v-icon>
            <div class="flex flex-row justify-between">
            <span class="title">Messages</span>
            <span v-if="unreadCount !== 0 " class=" z-10 text-[12px] w-[25px] min-w-[25px] badge rounded-pill flex bg-danger items-center justify-center aspect-square ">{{unreadCount}}</span>
            </div>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/faq" class="sidebar-nav-link  d-block">
            <i class="fa fa-question-circle"></i>
            <span class="title">FAQ</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/admin/siteinfo" class="sidebar-nav-link  d-block">
            <i class="fa fa-info-circle"></i>
            <span class="title">Site Information</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <button @click="signOut" class="sidebar-nav-link d-block">
            <i class="flaticon-logout"></i>
            <span class="title">Logout</span>
          </button>
        </li>
      </ul>
    </div>
    <div class="sidebar-menu" v-else>
      <ul class="sidebar-navbar-nav ps-0 mb-0 list-unstyled accordion" id="sidebarNavAccordion">
        <li class="sidebar-nav-item">
          <router-link to="/buy-new-proxy" class="sidebar-nav-link  d-block">
            <i class="flaticon-widget"></i>
            <span class="title">Buy New Proxy</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/my-proxy" class="sidebar-nav-link d-block">
            <i class="flaticon-cubes"></i>
            <span class="title">My Proxy</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/recharge" class="sidebar-nav-link d-block">
            <i class="flaticon-money"></i>
            <span class="title">Deposit / Transactions</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/account" class="sidebar-nav-link d-block">
            <i class="flaticon-user"></i>
            <span class="title">Account</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/referral" class="sidebar-nav-link  d-block">
            <v-icon icon="mdi-account-tie"></v-icon>
            <span class="title">Affiliate program</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/faq" class="sidebar-nav-link  d-block">
            <i class="fa fa-question-circle"></i>
            <span class="title">FAQ</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <button @click="signOut" class="sidebar-nav-link d-block">
            <v-icon icon="mdi-account"></v-icon>
            <span class="title">Logout</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script  setup>
import stateStore from "../../utils/store";

import { useAccountStore } from "@/stores";
import { storeToRefs } from "pinia";
const accountStore = useAccountStore()
import { useDarkModeStore } from "@/stores";
const stateStoreInstance = stateStore;
import {useChat} from "@/firebase.js";
import { computed, watch } from "vue";
const {setUserId, allCount} = useChat()
const darkStore = useDarkModeStore();
const {isDarkMode} = storeToRefs(darkStore);

let unreadCount = computed(() => {
  return allCount.value;
})

const {user, unread} = storeToRefs(accountStore)
watch(user, () => {
  if(user.value.role==='admin') {
    setUserId(0, true)
  } else {
    setUserId(0,0)
  }
})

const signOut = () => {
  accountStore.signout()
}



</script>